import React, { useState, useEffect, forwardRef, useRef } from "react";
import "../../resources/css/admin.css";
import {
  FaCheck,
  FaEdit,
  FaTrash,
  FaList,
  FaPlus,
  FaSignOutAlt,
  FaPrint,
  FaTimes,
  FaSearch,
  FaIconDashboard,
  FaExclamationCircle,
  FaShoppingCart,
  FaCoins,
} from "react-icons/fa";
var CryptoJS = require("crypto-js");

//import Navbar from '../navbars/Navbar';
const calendar_logo = require("../../resources/img/icons-calendar.png");
const payIcon = require("../../resources/img/pay.png");

const base_url = "https://spin.bruktiethiotour.com/";

var current_game_id = 0;
var openStatus = "closed";

const People = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isError, setIsError] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [currentGameId, setCurrentGameId] = useState("NONE");
  const [isBetOpen, setIsBetOpen] = useState(true);
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [currentGameLevel, setCurrentGameLevel] = useState("0");
  const [currentGameLength, setCurrentGameLength] = useState("300000");
  const [currentOpenStatus, setCurrentOpenStatus] = useState("");
  const [currentOnhandMoney, setCurrentOnhandMoney] = useState("");
  //cashiers
  const [allCashiers, setAllCashiers] = useState([]);
  const [cashierShow, setCashierShow] = useState("all");
  const [cashierUsername, setCashierUsername] = useState("");
  const [cashierFullname, setCashierFullname] = useState("");
  const [cashierShopNumber, setCashierShopNumber] = useState("");
  const [cashierPassword, setCashierPassword] = useState("");
  const [cashierConfirmPassword, setCashierConfirmPassword] = useState("");
  const [cashierEditId, setCashierEditId] = useState(0);
  const [cashierError, setCashierError] = useState(false);
  const [cashierErrorMessage, setCashierErrorMessage] = useState("");
  const [cashierSuccess, setCashierSuccess] = useState(false);
  const [cashierSuccessMessage, setCashierSuccessMessage] = useState("");
  const [isCashierDeleteClicked, setIsCashierDeleteClicked] = useState(false);
  const [cashierDeleteId, setCashierDeleteId] = useState(0);
  const [cashierDeleteUsername, setCashierDeleteUsername] = useState(0);
  //admins
  const [allAdmins, setAllAdmins] = useState([]);
  const [adminShow, setAdminShow] = useState("all");
  const [adminUsername, setAdminUsername] = useState("");
  const [adminFullname, setAdminFullname] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminConfirmPassword, setAdminConfirmPassword] = useState("");
  const [adminEditId, setAdminEditId] = useState(0);
  const [adminError, setAdminError] = useState(false);
  const [adminErrorMessage, setAdminErrorMessage] = useState("");
  const [adminSuccess, setAdminSuccess] = useState(false);
  const [adminSuccessMessage, setAdminSuccessMessage] = useState("");
  const [isAdminDeleteClicked, setIsAdminDeleteClicked] = useState(false);
  const [adminDeleteId, setAdminDeleteId] = useState(0);
  const [adminDeleteUsername, setAdminDeleteUsername] = useState(0);
  //partners
  const [allPartners, setAllPartners] = useState([]);
  const [partnerShow, setPartnerShow] = useState("all");
  const [partnerUsername, setPartnerUsername] = useState("");
  const [partnerFullname, setPartnerFullname] = useState("");
  const [partnerPassword, setPartnerPassword] = useState("");
  const [partnerConfirmPassword, setPartnerConfirmPassword] = useState("");
  const [partnerEditId, setPartnerEditId] = useState(0);
  const [partnerError, setPartnerError] = useState(false);
  const [partnerErrorMessage, setPartnerErrorMessage] = useState("");
  const [partnerSuccess, setPartnerSuccess] = useState(false);
  const [partnerSuccessMessage, setPartnerSuccessMessage] = useState("");
  const [isPartnerDeleteClicked, setIsPartnerDeleteClicked] = useState(false);
  const [partnerDeleteId, setPartnerDeleteId] = useState(0);
  const [partnerDeleteUsername, setPartnerDeleteUsername] = useState(0);

  // const [numBets, setNumBets] = useState(0);
  // const [totalBets, setTotalBets] = useState(0);

  function get_all_cashiers() {
    const url = `${base_url}all_tables/users/getallcashiers`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            //  console.log(json);

            setAllCashiers(json);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }

  function get_all_admins() {
    const url = `${base_url}all_tables/users/getalladmins`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            //  console.log(json);

            setAllAdmins(json);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }

  function get_all_partners() {
    const url = `${base_url}all_tables/users/getallpartners`;
    const res = fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            //  console.log(json);

            setAllPartners(json);
            //  setFetchedData(json);
          });
        } else {
          // console.log("not fetched");
        }
      })
      .catch((err) => {
        // console.log("AXIOS ERROR: ", err);
        //     console.log("not fetched 2");
      });
  }

  function cashier_list_clicked() {
    setCashierError(false);
    setCashierErrorMessage("");
    setCashierSuccess(false);
    setCashierSuccessMessage("");
    get_all_cashiers();
    setCashierShow("all");
    setCashierUsername("");
    setCashierFullname("");
    setCashierShopNumber("");
    setCashierPassword("");
    setCashierConfirmPassword("");
  }
  function admin_list_clicked() {
    setAdminError(false);
    setAdminErrorMessage("");
    setAdminSuccess(false);
    setAdminSuccessMessage("");
    get_all_admins();
    setAdminShow("all");
    setAdminUsername("");
    setAdminFullname("");

    setAdminPassword("");
    setAdminConfirmPassword("");
  }
  function partner_list_clicked() {
    setPartnerError(false);
    setPartnerErrorMessage("");
    setPartnerSuccess(false);
    setPartnerSuccessMessage("");
    get_all_partners();
    setPartnerShow("all");
    setPartnerUsername("");
    setPartnerFullname("");

    setPartnerPassword("");
    setPartnerConfirmPassword("");
  }
  function cashier_new_clicked() {
    setCashierError(false);
    setCashierSuccess(false);
    setCashierErrorMessage("");
    setCashierSuccessMessage("");
    setCashierShow("new");
    setCashierUsername("");
    setCashierFullname("");
    setCashierShopNumber("");
    setCashierPassword("");
    setCashierConfirmPassword("");
  }
  function admin_new_clicked() {
    setAdminError(false);
    setAdminSuccess(false);
    setAdminErrorMessage("");
    setAdminSuccessMessage("");
    setAdminShow("new");
    setAdminUsername("");
    setAdminFullname("");

    setAdminPassword("");
    setAdminConfirmPassword("");
  }
  function partner_new_clicked() {
    setPartnerError(false);
    setPartnerSuccess(false);
    setPartnerErrorMessage("");
    setPartnerSuccessMessage("");
    setPartnerShow("new");
    setPartnerUsername("");
    setPartnerFullname("");

    setPartnerPassword("");
    setPartnerConfirmPassword("");
  }

  function add_cashier() {
    setCashierError(false);
    setCashierSuccess(false);
    setCashierErrorMessage("");
    setCashierSuccessMessage("");
    //   const [cashierAddError, setCashierAddError] = useState(false);
    //   const [cashierAddErrorMessage, setCashierAddErrorMessage]  = useState("");
    if (
      cashierUsername == "" ||
      cashierFullname == "" ||
      cashierPassword == "" ||
      cashierConfirmPassword == "" ||
      cashierShopNumber == ""
    ) {
      setCashierError(true);
      setCashierErrorMessage("All fields are required");
    } else {
      if (cashierPassword != cashierConfirmPassword) {
        setCashierError(true);
        setCashierErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/addcashier`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: cashierUsername,
            fullName: cashierFullname,
            password: cashierPassword,
            shopNumber: cashierShopNumber,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setCashierSuccess(true);
                  setCashierSuccessMessage("Successfully saved");

                  setCashierUsername("");
                  setCashierFullname("");
                  setCashierShopNumber("");
                  setCashierPassword("");
                  setCashierConfirmPassword("");
                } else {
                  setCashierError(true);
                  setCashierErrorMessage("Cashier already exists");
                }
              });
            } else {
              setCashierError(true);
              setCashierErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setCashierError(true);
            setCashierErrorMessage("Connection not working");
          });
      }
    }
  }
  function add_admin() {
    setAdminError(false);
    setAdminSuccess(false);
    setAdminErrorMessage("");
    setAdminSuccessMessage("");
    //   const [cashierAddError, setCashierAddError] = useState(false);
    //   const [cashierAddErrorMessage, setCashierAddErrorMessage]  = useState("");
    if (
      adminUsername == "" ||
      adminFullname == "" ||
      adminPassword == "" ||
      adminConfirmPassword == ""
    ) {
      setAdminError(true);
      setAdminErrorMessage("All fields are required");
    } else {
      if (adminPassword != adminConfirmPassword) {
        setAdminError(true);
        setAdminErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/addadmin`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: adminUsername,
            fullName: adminFullname,
            password: adminPassword,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setAdminSuccess(true);
                  setAdminSuccessMessage("Successfully saved");

                  setAdminUsername("");
                  setAdminFullname("");

                  setAdminPassword("");
                  setAdminConfirmPassword("");
                } else {
                  setAdminError(true);
                  setAdminErrorMessage("Admin already exists");
                }
              });
            } else {
              setAdminError(true);
              setAdminErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setAdminError(true);
            setAdminErrorMessage("Connection not working");
          });
      }
    }
  }
  function add_partner() {
    setPartnerError(false);
    setPartnerSuccess(false);
    setPartnerErrorMessage("");
    setPartnerSuccessMessage("");
    //   const [cashierAddError, setCashierAddError] = useState(false);
    //   const [cashierAddErrorMessage, setCashierAddErrorMessage]  = useState("");
    if (
      partnerUsername == "" ||
      partnerFullname == "" ||
      partnerPassword == "" ||
      partnerConfirmPassword == ""
    ) {
      setPartnerError(true);
      setPartnerErrorMessage("All fields are required");
    } else {
      if (partnerPassword != partnerConfirmPassword) {
        setPartnerError(true);
        setPartnerErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/addpartner`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: partnerUsername,
            fullName: partnerFullname,
            password: partnerPassword,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setPartnerSuccess(true);
                  setPartnerSuccessMessage("Successfully saved");

                  setPartnerUsername("");
                  setPartnerFullname("");

                  setPartnerPassword("");
                  setPartnerConfirmPassword("");
                } else {
                  setPartnerError(true);
                  setPartnerErrorMessage("Admin already exists");
                }
              });
            } else {
              setPartnerError(true);
              setPartnerErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setPartnerError(true);
            setPartnerErrorMessage("Connection not working");
          });
      }
    }
  }
  function cashier_edit_clicked(
    username,
    full_name,
    shop_number,
    id,
    password
  ) {
    setCashierError(false);
    setCashierErrorMessage("");
    setCashierSuccess(false);
    setCashierSuccessMessage("");
    setCashierShow("edit");

    var bytes = CryptoJS.AES.decrypt(password, "my-secret-key@123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setCashierPassword(decryptedData);
    setCashierConfirmPassword(decryptedData);
    setCashierUsername(username);
    setCashierFullname(full_name);
    setCashierShopNumber(shop_number);
    setCashierEditId(id);
  }
  function admin_edit_clicked(username, full_name, id, password) {
    setAdminError(false);
    setAdminErrorMessage("");
    setAdminSuccess(false);
    setAdminSuccessMessage("");
    setAdminShow("edit");

    var bytes = CryptoJS.AES.decrypt(password, "my-secret-key@123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setAdminPassword(decryptedData);
    setAdminConfirmPassword(decryptedData);
    setAdminUsername(username);
    setAdminFullname(full_name);

    setAdminEditId(id);
  }
  function partner_edit_clicked(username, full_name, id, password) {
    setPartnerError(false);
    setPartnerErrorMessage("");
    setPartnerSuccess(false);
    setPartnerSuccessMessage("");
    setPartnerShow("edit");

    var bytes = CryptoJS.AES.decrypt(password, "my-secret-key@123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setPartnerPassword(decryptedData);
    setPartnerConfirmPassword(decryptedData);
    setPartnerUsername(username);
    setPartnerFullname(full_name);

    setPartnerEditId(id);
  }
  function cashier_delete_clicked(id, username) {
    setCashierDeleteId(id);
    setCashierDeleteUsername(username);
    setIsCashierDeleteClicked(true);
  }
  function admin_delete_clicked(id, username) {
    setAdminDeleteId(id);
    setAdminDeleteUsername(username);
    setIsAdminDeleteClicked(true);
  }
  function partner_delete_clicked(id, username) {
    setPartnerDeleteId(id);
    setPartnerDeleteUsername(username);
    setIsPartnerDeleteClicked(true);
  }
  function delete_cashier() {
    setIsCashierDeleteClicked(false);
    const url = `${base_url}all_tables/users/deletecashier`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: cashierDeleteId }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            get_all_cashiers();
          });
        } else {
          //   setCashierErrorMessage("Connection not working");
        }
      })
      .catch((err) => {
        //  setCashierErrorMessage("Connection not working");
      });
  }

  function delete_admin() {
    setIsAdminDeleteClicked(false);
    const url = `${base_url}all_tables/users/deleteadmin`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: adminDeleteId }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            get_all_admins();
          });
        } else {
          //  setAdminErrorMessage("Connection not working");
        }
      })
      .catch((err) => {
        //  setAdminErrorMessage("Connection not working");
      });
  }
  function delete_partner() {
    setIsPartnerDeleteClicked(false);
    const url = `${base_url}all_tables/users/deletepartner`;
    const res = fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: partnerDeleteId }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            // setTm(json);
            // console.log(json);
            // setFetchedData(json);
            get_all_partners();
          });
        } else {
          //  setAdminErrorMessage("Connection not working");
        }
      })
      .catch((err) => {
        //  setAdminErrorMessage("Connection not working");
      });
  }

  function edit_cashier() {
    setCashierError(false);
    setCashierErrorMessage("");
    setCashierSuccess(false);
    setCashierSuccessMessage("");

    if (
      cashierUsername == "" ||
      cashierFullname == "" ||
      cashierPassword == "" ||
      cashierConfirmPassword == "" ||
      cashierShopNumber == ""
    ) {
      setCashierError(true);
      setCashierErrorMessage("All fields are required");
    } else {
      if (cashierPassword != cashierConfirmPassword) {
        setCashierError(true);
        setCashierErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/editcashier`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: cashierUsername,
            fullName: cashierFullname,
            password: cashierPassword,
            shopNumber: cashierShopNumber,
            id: cashierEditId,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setCashierSuccess(true);
                  setCashierSuccessMessage("Successfully saved");

                  // setCashierUsername("");
                  // setCashierFullname("");
                  // setCashierShopNumber("");
                  // setCashierPassword("");
                  // setCashierConfirmPassword("");
                } else {
                  setCashierError(true);
                  setCashierErrorMessage("Cashier already exists");
                }
              });
            } else {
              setCashierError(true);
              setCashierErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setCashierError(true);
            setCashierErrorMessage("Connection not working");
          });
      }
    }
  }

  function edit_admin() {
    setAdminError(false);
    setAdminErrorMessage("");
    setAdminSuccess(false);
    setAdminSuccessMessage("");

    if (
      adminUsername == "" ||
      adminFullname == "" ||
      adminPassword == "" ||
      adminConfirmPassword == ""
    ) {
      setAdminError(true);
      setAdminErrorMessage("All fields are required");
    } else {
      if (adminPassword != adminConfirmPassword) {
        setAdminError(true);
        setAdminErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/editadmin`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: adminUsername,
            fullName: adminFullname,
            password: adminPassword,
            id: adminEditId,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setAdminSuccess(true);
                  setAdminSuccessMessage("Successfully saved");

                  // setCashierUsername("");
                  // setCashierFullname("");
                  // setCashierShopNumber("");
                  // setCashierPassword("");
                  // setCashierConfirmPassword("");
                } else {
                  setAdminError(true);
                  setAdminErrorMessage("Cashier already exists");
                }
              });
            } else {
              setAdminError(true);
              setAdminErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setAdminError(true);
            setAdminErrorMessage("Connection not working");
          });
      }
    }
  }

  function edit_partner() {
    setPartnerError(false);
    setPartnerErrorMessage("");
    setPartnerSuccess(false);
    setPartnerSuccessMessage("");

    if (
      partnerUsername == "" ||
      partnerFullname == "" ||
      partnerPassword == "" ||
      partnerConfirmPassword == ""
    ) {
      setPartnerError(true);
      setPartnerErrorMessage("All fields are required");
    } else {
      if (partnerPassword != partnerConfirmPassword) {
        setPartnerError(true);
        setPartnerErrorMessage("Password must match confirm password");
      } else {
        // we are good to add the cashier
        const url = `${base_url}all_tables/users/editpartner`;
        const res = fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            username: partnerUsername,
            fullName: partnerFullname,
            password: partnerPassword,
            id: partnerEditId,
          }),
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((json) => {
                // setTm(json);
                // console.log(json);
                // setFetchedData(json);

                if (json.saved == "1") {
                  setPartnerSuccess(true);
                  setPartnerSuccessMessage("Successfully saved");

                  // setCashierUsername("");
                  // setCashierFullname("");
                  // setCashierShopNumber("");
                  // setCashierPassword("");
                  // setCashierConfirmPassword("");
                } else {
                  setPartnerError(true);
                  setPartnerErrorMessage("Cashier already exists");
                }
              });
            } else {
              setPartnerError(true);
              setPartnerErrorMessage("Connection not working");
            }
          })
          .catch((err) => {
            setPartnerError(true);
            setPartnerErrorMessage("Connection not working");
          });
      }
    }
  }

  useEffect(() => {
    get_all_cashiers();
    get_all_admins();
    get_all_partners();
  }, []);

  return (
    <div style={{ marginBottom: "-600px" }}>
      <div
        style={{
          height: "150px",
          background: "#86A879",
          padding: "1.2rem 2rem",
        }}
      >
        <span style={{ color: "#fff" }}>
          <span style={{ color: "#dcdcdc" }}>Admin</span> / People
        </span>
        <br></br>
        <span style={{ color: "#fff" }}>Stats</span>
      </div>

      <div
        className="admin_rep_level"
        style={{ background: "#fff", borderRadius: "10px" }}
      >
        <div style={{ marginBottom: "10px" }}>CASHIERS</div>
        <div>
          <button
            className="admin_btn_people_menu"
            onClick={() => cashier_list_clicked()}
          >
            <FaList
              style={{ marginTop: "-3px", marginRight: "3px", color: "green" }}
            />
            List
          </button>
          <button
            className="admin_btn_people_menu"
            onClick={() => cashier_new_clicked()}
            disabled={cashierShow == "new" && "disabled"}
          >
            <FaPlus
              style={{
                marginTop: "-3px",
                marginRight: "3px",
                color: cashierShow == "new" ? "grey" : "green",
              }}
            />
            New
          </button>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <span style={{ color: "#999" }}>Cashiers</span> /{" "}
          <span>
            {cashierShow == "new"
              ? "new"
              : cashierShow == "edit"
              ? "edit"
              : "list"}
          </span>
        </div>
        {cashierShow == "all" && (
          <div className="table-responsive" style={{ marginTop: "20px" }}>
            <table className="table admin_tbl_people">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Full Name</th>
                  <th scope="col">Shop</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {allCashiers.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.username}</td>
                      <td>{item.full_name}</td>
                      <td>{item.shop_number}</td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            cashier_edit_clicked(
                              item.username,
                              item.full_name,
                              item.shop_number,
                              item.id,
                              item.password
                            )
                          }
                        >
                          <FaEdit style={{ color: "green" }} />
                        </span>
                      </td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            cashier_delete_clicked(item.id, item.username)
                          }
                        >
                          <FaTrash style={{ color: "red" }} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {cashierShow == "new" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {cashierError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{cashierErrorMessage}</span>
                </div>
              )}
              {cashierSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>
                    {cashierSuccessMessage}
                  </span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={cashierUsername}
                    onChange={(e) => setCashierUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={cashierFullname}
                    onChange={(e) => setCashierFullname(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Shop Number:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={cashierShopNumber}
                    onChange={(e) => setCashierShopNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={cashierPassword}
                    onChange={(e) => setCashierPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={cashierConfirmPassword}
                    onChange={(e) => setCashierConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => add_cashier()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {cashierShow == "edit" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {cashierError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{cashierErrorMessage}</span>
                </div>
              )}
              {cashierSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>
                    {cashierSuccessMessage}
                  </span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div
                className="admin_people_new_row"
                style={{ fontWeight: "600" }}
              >
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  {cashierUsername}{" "}
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={cashierFullname}
                    onChange={(e) => setCashierFullname(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Shop Number:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={cashierShopNumber}
                    onChange={(e) => setCashierShopNumber(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={cashierPassword}
                    onChange={(e) => setCashierPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={cashierConfirmPassword}
                    onChange={(e) => setCashierConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => edit_cashier()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="admin_rep_level"
        style={{ background: "#fff", borderRadius: "10px", marginTop: "30px" }}
      >
        <div style={{ marginBottom: "10px" }}>ADMINS</div>
        <div>
          <button
            className="admin_btn_people_menu"
            onClick={() => admin_list_clicked()}
          >
            <FaList
              style={{ marginTop: "-3px", marginRight: "3px", color: "green" }}
            />
            List
          </button>
          <button
            className="admin_btn_people_menu"
            onClick={() => admin_new_clicked()}
            disabled={adminShow == "new" && "disabled"}
          >
            <FaPlus
              style={{
                marginTop: "-3px",
                marginRight: "3px",
                color: adminShow == "new" ? "grey" : "green",
              }}
            />
            New
          </button>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <span style={{ color: "#999" }}>Admins</span> /{" "}
          <span>
            {adminShow == "new" ? "new" : adminShow == "edit" ? "edit" : "list"}
          </span>
        </div>
        {adminShow == "all" && (
          <div className="table-responsive" style={{ marginTop: "20px" }}>
            <table className="table admin_tbl_people">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Full Name</th>

                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {allAdmins.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.username}</td>
                      <td>{item.full_name}</td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            admin_edit_clicked(
                              item.username,
                              item.full_name,
                              item.id,
                              item.password
                            )
                          }
                        >
                          <FaEdit style={{ color: "green" }} />
                        </span>
                      </td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            admin_delete_clicked(item.id, item.username)
                          }
                        >
                          <FaTrash style={{ color: "red" }} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {adminShow == "new" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {adminError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{adminErrorMessage}</span>
                </div>
              )}
              {adminSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>{adminSuccessMessage}</span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={adminUsername}
                    onChange={(e) => setAdminUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={adminFullname}
                    onChange={(e) => setAdminFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={adminConfirmPassword}
                    onChange={(e) => setAdminConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => add_admin()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {adminShow == "edit" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {adminError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{adminErrorMessage}</span>
                </div>
              )}
              {adminSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>{adminSuccessMessage}</span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div
                className="admin_people_new_row"
                style={{ fontWeight: "600" }}
              >
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  {adminUsername}{" "}
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={adminFullname}
                    onChange={(e) => setAdminFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={adminPassword}
                    onChange={(e) => setAdminPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={adminConfirmPassword}
                    onChange={(e) => setAdminConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => edit_admin()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="admin_rep_level"
        style={{ background: "#fff", borderRadius: "10px", marginTop: "30px" }}
      >
        <div style={{ marginBottom: "10px" }}>PARTNERS</div>
        <div>
          <button
            className="admin_btn_people_menu"
            onClick={() => partner_list_clicked()}
          >
            <FaList
              style={{ marginTop: "-3px", marginRight: "3px", color: "green" }}
            />
            List
          </button>
          <button
            className="admin_btn_people_menu"
            onClick={() => partner_new_clicked()}
            disabled={partnerShow == "new" && "disabled"}
          >
            <FaPlus
              style={{
                marginTop: "-3px",
                marginRight: "3px",
                color: partnerShow == "new" ? "grey" : "green",
              }}
            />
            New
          </button>
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <span style={{ color: "#999" }}>Partners</span> /{" "}
          <span>
            {partnerShow == "new"
              ? "new"
              : partnerShow == "edit"
              ? "edit"
              : "list"}
          </span>
        </div>
        {partnerShow == "all" && (
          <div className="table-responsive" style={{ marginTop: "20px" }}>
            <table className="table admin_tbl_people">
              <thead>
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Full Name</th>

                  <th scope="col">Edit</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {allPartners.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{item.username}</td>
                      <td>{item.full_name}</td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            partner_edit_clicked(
                              item.username,
                              item.full_name,
                              item.id,
                              item.password
                            )
                          }
                        >
                          <FaEdit style={{ color: "green" }} />
                        </span>
                      </td>
                      <td>
                        <span
                          className="spn_edit_del"
                          onClick={() =>
                            partner_delete_clicked(item.id, item.username)
                          }
                        >
                          <FaTrash style={{ color: "red" }} />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}

        {partnerShow == "new" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {partnerError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{partnerErrorMessage}</span>
                </div>
              )}
              {partnerSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>
                    {partnerSuccessMessage}
                  </span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={partnerUsername}
                    onChange={(e) => setPartnerUsername(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={partnerFullname}
                    onChange={(e) => setPartnerFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={partnerPassword}
                    onChange={(e) => setPartnerPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={partnerConfirmPassword}
                    onChange={(e) => setPartnerConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => add_partner()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {partnerShow == "edit" && (
          <div>
            <div
              style={{
                height: "30px",
                marginBottom: "10px",
                marginTop: "0px",
                padding: "5px 10px",
                textAlign: "center",
              }}
            >
              {partnerError && (
                <div style={{ display: "inline-block" }}>
                  <FaExclamationCircle
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "red",
                    }}
                  />
                  <span style={{ color: "red" }}>{partnerErrorMessage}</span>
                </div>
              )}
              {partnerSuccess && (
                <div style={{ display: "inline-block" }}>
                  <FaCheck
                    style={{
                      marginRight: "5px",
                      marginTop: "-3px",
                      color: "green",
                    }}
                  />
                  <span style={{ color: "green" }}>
                    {partnerSuccessMessage}
                  </span>
                </div>
              )}
            </div>

            <div className="admin_people_new">
              <div
                className="admin_people_new_row"
                style={{ fontWeight: "600" }}
              >
                <div className="admin_people_new_td_left">
                  <span>Username:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  {partnerUsername}{" "}
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Full Name:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  {" "}
                  <input
                    type="text"
                    value={partnerFullname}
                    onChange={(e) => setPartnerFullname(e.target.value)}
                  />
                </div>
              </div>

              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={partnerPassword}
                    onChange={(e) => setPartnerPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left">
                  <span>Confirm Password:</span>{" "}
                </div>
                <div className="admin_people_new_td_right">
                  <input
                    type="password"
                    value={partnerConfirmPassword}
                    onChange={(e) => setPartnerConfirmPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="admin_people_new_row">
                <div className="admin_people_new_td_left"> </div>
                <div className="admin_people_new_td_right">
                  <button
                    onClick={() => edit_partner()}
                    className="admin_btn_status_change"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          display: isCashierDeleteClicked ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",

          background: "rgba(0,0,0,0.8)",
          zIndex: "500",
        }}
      >
        {/* <h2 style = {{color: "#fff"}}>BET IS CLOSED</h2> */}
        <div
          style={{ padding: "20px", background: "#fff", borderRadius: "5px" }}
        >
          <span>
            Are you certain you want to delete {cashierDeleteUsername}?{" "}
          </span>
          <br></br>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={() => delete_cashier()}
              className="admin_btn_status_change"
              style={{ marginRight: "5px" }}
            >
              Yes
            </button>
            <button
              onClick={() => setIsCashierDeleteClicked(false)}
              className="admin_btn_status_change"
              style={{ marginLeft: "5px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          display: isAdminDeleteClicked ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",

          background: "rgba(0,0,0,0.8)",
          zIndex: "500",
        }}
      >
        {/* <h2 style = {{color: "#fff"}}>BET IS CLOSED</h2> */}
        <div
          style={{ padding: "20px", background: "#fff", borderRadius: "5px" }}
        >
          <span>
            Are you certain you want to delete {adminDeleteUsername}?{" "}
          </span>
          <br></br>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={() => delete_admin()}
              className="admin_btn_status_change"
              style={{ marginRight: "5px" }}
            >
              Yes
            </button>
            <button
              onClick={() => setIsAdminDeleteClicked(false)}
              className="admin_btn_status_change"
              style={{ marginLeft: "5px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          top: "0px",
          bottom: "0px",
          right: "0px",
          left: "0px",
          display: isPartnerDeleteClicked ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",

          background: "rgba(0,0,0,0.8)",
          zIndex: "500",
        }}
      >
        {/* <h2 style = {{color: "#fff"}}>BET IS CLOSED</h2> */}
        <div
          style={{ padding: "20px", background: "#fff", borderRadius: "5px" }}
        >
          <span>
            Are you certain you want to delete {partnerDeleteUsername}?{" "}
          </span>
          <br></br>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <button
              onClick={() => delete_partner()}
              className="admin_btn_status_change"
              style={{ marginRight: "5px" }}
            >
              Yes
            </button>
            <button
              onClick={() => setIsPartnerDeleteClicked(false)}
              className="admin_btn_status_change"
              style={{ marginLeft: "5px" }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default People;
