import React, { useState, useEffect, forwardRef, useRef } from "react";
import "../../resources/css/admin.css";
import {
  FaCheck,
  FaSignOutAlt,
  FaPrint,
  FaAngleLeft,
  FaAngleRight,
  FaTimes,
  FaSearch,
  FaIconDashboard,
  FaExclamationCircle,
  FaShoppingCart,
  FaCoins,
} from "react-icons/fa";

//import Navbar from '../navbars/Navbar';
const calendar_logo = require("../../resources/img/icons-calendar.png");
const payIcon = require("../../resources/img/pay.png");

const base_url = "https://spin.bruktiethiotour.com/";

const Tickets = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [isError, setIsError] = useState(true);
  const [fetchedData, setFetchedData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [initialIndex, setInitialIndex] = useState(0);
  const [finalIndex, setFinalIndex] = useState(9);
  const [pageNum, setPageNum] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState("all");

  const tickets_by_day = async (from, to) => {
    const x = new Date(from);
    const y = new Date(to);
    if (x <= y && from != "" && to != "") {
      setIsError(false);
      const res = await fetch(`${base_url}all_tables/users/ticketsbyday`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          from_date: from,
          to_date: to,
          search_criteria: searchCriteria,
        }),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((json) => {
              // setTm(json);
              console.log(json);
              setFetchedData(json);
              setTotalRows(json.length);
              setTotalRows(json.length);
              setInitialIndex(0);
              setFinalIndex(rowsPerPage - 1);

              setPageCount(() => {
                return Math.ceil(json.length / rowsPerPage);
              });
              if (json.length == 0) {
                setPageNum(0);
              } else {
                setPageNum(1);
              }
            });
          } else {
            setIsError(true);
          }
        })
        .catch((err) => {
          setIsError(true);
          console.log("connection not working");
        });
    } else {
      setIsError(true);
    }
  };

  const pageIndices = (num) => {
    const options = [];
    obj = {};

    for (var i = 1; i <= num; i++) {
      var obj = {};

      obj["value"] = i;
      obj["label"] = i;
      options.push(obj);
    }
    return options;
  };
  const btn_prev_page = (e) => {
    e.preventDefault();
    if (initialIndex == 0) {
      //do nothing
    } else {
      setInitialIndex(() => {
        setFinalIndex(finalIndex - rowsPerPage);
        setPageNum(pageNum - 1);
        return initialIndex - rowsPerPage;
      });
    }
  };
  const btn_next_page = (e) => {
    e.preventDefault();
    if (finalIndex >= totalRows - 1) {
      //do nothing
    } else {
      setInitialIndex(() => {
        setFinalIndex(finalIndex + rowsPerPage);
        setPageNum(pageNum + 1);
        return initialIndex + rowsPerPage;
      });
    }
  };

  useEffect(() => {
    const d = new Date();

    const prev_d = new Date();

    d.setDate(d.getDate() + 1);
    const d_str = d
      .toISOString()
      .replace("-", "/")
      .split("T")[0]
      .replace("-", "/");
    prev_d.setDate(prev_d.getDate() - 6);
    const prev_d_str = prev_d
      .toISOString()
      .replace("-", "/")
      .split("T")[0]
      .replace("-", "/");
    // let text = d.toString();
    //stat_report_by_day_cashier(prev_d_str, d_str);
    setFromDate(prev_d.toISOString().substring(0, 10));
    setToDate(d.toISOString().substring(0, 10));

    //tickets_by_day(prev_d_str, d_str);
    tickets_by_day(
      prev_d.toISOString().substring(0, 10),
      d.toISOString().substring(0, 10)
    );

    //console.log(next_d_str.split("/")[1]+"/"+next_d_str.split("/")[2]+"/"+next_d_str.split("/")[0]);
  }, []);

  return (
    //   <div className = "admin_container" style = {{ background: "#D3D3D3", minHeight: "100vh" }}>
    // <React.Fragment>
    //     <Navbar handlePage = {change_page} />
    // </React.Fragment>
    <div>
      <div
        style={{
          height: "150px",
          background: "#86A879",
          padding: "1.2rem 2rem",
        }}
      >
        <span style={{ color: "#fff" }}>
          <span style={{ color: "#dcdcdc" }}>Partner</span> / Tickets
        </span>
        <br></br>
        <span style={{ color: "#fff" }}>Stats</span>
      </div>

      {/* <div className = "row" style = {{ color: "#fff"}}>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      <div className = "col-lg-6">
        fdgdgdf
      </div>
      </div> */}
      <div
        className="admin_rep"
        style={{
          background: "#fff",
          borderRadius: "10px",
          marginTop: "-40px",
          marginBottom: "-160px",
        }}
      >
        <div className="admin_date_range">
          <div>
            <label className="admin_rep_date_lbl">Select Report Date: </label>
          </div>
          <div>
            {" "}
            <div
              style={{
                position: "relative",
                display: "inline-block",
                zIndex: "1",
              }}
            >
              <input
                type="date"
                className="admin_input_date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label className="admin_lbl">From</label>
            </div>{" "}
          </div>
          <div>
            {" "}
            <div style={{ position: "relative", display: "inline-block" }}>
              <input
                type="date"
                className="admin_input_date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
              <label className="admin_lbl">To</label>
            </div>{" "}
          </div>
          <div>
            <select
              className="slc_page slc_search_criterea"
              style={{ height: "37px" }}
              value={searchCriteria}
              onChange={(e) => setSearchCriteria(e.target.value)}
            >
              <option value="all">all</option>
              <option value="cancelled">cancelled</option>
              <option value="paid">paid</option>
              <option value="winner">winner</option>
              <option value="loser">loser</option>
              <option value="pending">pending</option>
            </select>
          </div>
          <div>
            <button onClick={() => tickets_by_day(fromDate, toDate)}>
              <FaSearch />
              &nbsp;Search
            </button>
          </div>
        </div>
        <div className="admin_report_tbl">
          {isError ? (
            <div
              style={{
                color: "#666",
                fontSize: "18px",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              <FaExclamationCircle style={{ marginBottom: "5px" }} />
              &nbsp;There is an error in the date specified
            </div>
          ) : (
            <div>
              {/* { fetchedData.bet_money_total.map((item, i) => {
                  return <span>{fetchedData.bet_money_total[0]}</span>
                }) }  */}
              {/* <span>{fetchedData.bet_money_total.length}</span> */}

              <div className="table-responsive" style={{ marginTop: "20px" }}>
                <table className="table admin_tbl_report">
                  <thead>
                    <tr>
                      <th scope="col">Ticket Number</th>
                      <th scope="col">Game Number</th>
                      <th scope="col">Bet Type</th>
                      <th scope="col">Bet Value</th>
                      <th scope="col">Bet Money</th>
                      <th scope="col">Win Money</th>
                      <th scope="col">Bet Time</th>
                      <th scope="col">Win Status</th>
                      <th scope="col">Cashier Name</th>
                      <th scope="col">Paid</th>
                      <th scope="col">Cancelled</th>
                      <th scope="col">Paid by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalRows > 0 ? (
                      fetchedData.map((item, index) => {
                        return (
                          index >= initialIndex &&
                          index <= finalIndex && (
                            <tr key={index}>
                              <td>{item.ticket_number}</td>
                              <td>{item.game_number}</td>
                              <td>{item.bet_type}</td>
                              <td>{item.bet_value}</td>
                              <td>{item.bet_money}</td>
                              <td>{item.win_money}</td>
                              <td>
                                {new Date(item.bet_time)
                                  .toISOString()
                                  .slice(0, 19)
                                  .replace("T", " ")}
                              </td>
                              <td>{item.win_status}</td>
                              <td>{item.cashier_name}</td>
                              <td>{item.paid}</td>
                              <td>{item.cancelled}</td>
                              <td>{item.paid_by}</td>
                            </tr>
                          )
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="11" style={{ textAlign: "center" }}>
                          No search result could be found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="card-footer">
                <div className="dv_page_setup">
                  <div className="dv_page_setup_mid">
                    <div className="dv_page_setup_small">
                      <label>Page {pageNum + "/" + pageCount} </label>
                    </div>
                    <div className="dv_page_setup_small">
                      <label style={{ fontSize: "14px" }}>Rows per page </label>
                      <select
                        className="slc_page"
                        value={rowsPerPage}
                        onChange={(e) =>
                          setRowsPerPage(() => {
                            setInitialIndex(0);
                            setFinalIndex(e.target.value - 1);
                            setPageNum(1);
                            setPageCount(() => {
                              return Math.ceil(
                                totalRows / (e.target.value * 1)
                              );
                            });
                            return e.target.value * 1;
                          })
                        }
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                      </select>
                    </div>
                  </div>
                  <div className="dv_page_setup_mid">
                    <div className="dv_page_setup_small">
                      <label style={{ fontSize: "14px" }}>Go to page</label>
                      <select
                        className="slc_page"
                        value={pageNum}
                        onChange={(e) =>
                          setPageNum(() => {
                            setInitialIndex(
                              e.target.value * 1 * rowsPerPage - rowsPerPage
                            );
                            setFinalIndex(e.target.value * 1 * rowsPerPage - 1);

                            return e.target.value * 1;
                          })
                        }
                      >
                        {pageIndices(pageCount).map(
                          ({ value, label }, index) => (
                            <option value={value} key={index}>
                              {label}
                            </option>
                          )
                        )}
                      </select>
                    </div>

                    <div className="dv_page_setup_small">
                      <a
                        href="#"
                        className={initialIndex == 0 ? "dull_lnk" : "previous"}
                        onClick={btn_prev_page}
                      >
                        <FaAngleLeft />
                      </a>
                      <a
                        href="#"
                        className={
                          finalIndex >= totalRows - 1 ? "dull_lnk" : "next"
                        }
                        onClick={btn_next_page}
                      >
                        <FaAngleRight />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Tickets;
